<template>
  <v-window v-model="step" class="elevation-1">
    <!-- first step -->
    <v-window-item :value="1">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn class="rounded-lg mr-3" icon @click="navigation('/main')">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>

          Dados Pessoais
        </v-card-title>

        <!-- personal data -->
        <PersonalData />

        <h1 class="mb-4 text-h6">Pagamento por PicPay</h1>

        <!-- awaiting -->
        <v-list-item v-if="awaitingMethods.includes('PICPAY')" class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Transação pendente,
            <a @click="continueTransaction()">clique para continuar</a>
          </v-list-item-title>
        </v-list-item>

        <!-- form -->
        <v-form
          v-else-if="!lockedMethods['PICPAY']"
          v-model="valid"
          ref="form"
          autocomplete="off"
        >
          <v-text-field
            v-model="form.username"
            v-mask="'@XXXXXXXXXXXXXXXXXXXX'"
            label="Seu usuário PicPay"
            class="mb-2"
            placeholder="@username"
            background-color="accent"
            :rules="rule"
            autofocus
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-pandora </v-icon>
            </template>
          </v-text-field>

          <v-btn color="primary" block large @click="submit()">
            Continuar Pagamento
          </v-btn>
        </v-form>

        <!-- unavaiable -->
        <v-list-item v-else class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Serviço indisponível para PicPay
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-window-item>

    <!-- second step -->
    <v-window-item :value="2">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn
            v-if="!transactionPerformed"
            class="rounded-lg mr-3"
            icon
            @click="navigation(`/checkout`)"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>

          Código PicPay
        </v-card-title>

        <v-card-subtitle class="text-body-1 px-0">
          Abra o aplicativo do PicPay e aponte a câmera para o
          <span class="secondary--text">QR Code</span>, você também pode acessar
          o <span class="secondary--text">link</span> copiando o endereço abaixo
          ou clicando no QR Code.
        </v-card-subtitle>

        <!-- picpay -->
        <section class="mb-8">
          <p class="text-center text-button secondary--text mb-4">
            {{ picPayUser }}
          </p>

          <!-- qr code -->
          <v-row class="justify-center mb-8" no-gutters>
            <v-card
              class="rounded-lg pa-4 elevation-3"
              color="white"
              width="250"
              height="250"
              target="_blank"
              :href="picPay"
              flat
            >
              <v-sheet color="transparent">
                <v-img :src="require('@/assets/picpay/qrcode.png')" contain />
              </v-sheet>
            </v-card>
          </v-row>

          <!-- picpay url -->
          <v-row class="mb-4" no-gutters>
            <input
              v-model="picPay"
              id="code"
              class="centered-input primary_text--text font-weight-regular text-caption accent pa-3"
              readonly
              @focus="copyCode()"
            />
          </v-row>

          <v-row class="justify-center" no-gutters>
            <v-btn color="secondary" text @click="copyCode()">
              <v-icon small left> mdi-content-copy </v-icon>
              COPIAR CÓDIGO
            </v-btn>
          </v-row>
        </section>

        <!-- receipt -->
        <v-card class="rounded-lg mb-6 px-4 pt-4" color="accent" flat>
          <p class="primary_text--text text-body-1">
            Para
            <span class="secondary--text">agilizar</span> o processo do seu
            pagamento, anexe o <span class="secondary--text">comprovante</span>.
          </p>

          <v-file-input
            v-model="file"
            accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
            placeholder="Anexar comprovante de pagamento"
            outlined
          />
        </v-card>

        <v-btn class="mb-6" color="primary" large block @click="finishHim()">
          Já fiz o pagamento <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <div v-if="jwtDecoded.transaction.redirectURL" class="text-center">
          <a :href="jwtDecoded.transaction.redirectURL" class="text-button">
            Retornar para o site
          </a>
        </div>
      </v-card>
    </v-window-item>

    <loader-hover v-if="loading" />
  </v-window>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { picpay, sendReceipt } from "@/services/payment";
import { getTransaction } from "@/services/checkout";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";
import PersonalData from "@/components/checkout/PersonalData";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      validReceipt: false,
      step: 1,
      form: {
        username: "",
      },
      transaction: {},
      // @TODO: Fazer um fluxo para pegar esse hash por uma requisição do backend
      picPay: "https://app.picpay.com/payment?type=store&hash=3LEqNp1vO5FM7I3j",
      // @TODO: Esse @user precisa vir do backend
      picPayUser: "@LOGUS PROVEDOR",
      file: null,
    };
  },

  components: {
    PersonalData,
  },

  directives: { mask },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      const method = this.jwtDecoded.transaction.method;

      if (method != "PICPAY") {
        return this.$router.push({
          path: `/checkout/payment/${method.toLowerCase()}`,
        });
      }
    }

    if (this.current) this.handleCurrent();
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "current",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),

    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    // submit picpay
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          transactionId: this.jwtDecoded.transaction.ref,
          username: this.form.username,
        };

        await picpay(payload).then((res) => {
          this.transaction = {
            id: res.body.data.id,
            transactionId: res.body.data.transactionId,
          };

          this.setTransactionPerformed(true);

          // get current transaction to commit
          this.getCurrentTransaction();

          this.step = 2;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // send receipt to finish
    async finishHim() {
      if (!this.file) {
        // navigation to review
        return this.navigation(
          `/checkout/summary/${this.transaction.transactionId}`
        );
      }

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        const headers = {
          ref: this.transaction.transactionId,
        };

        await sendReceipt(formData, headers).then(() => {
          this.displayAlert("Comprovante enviado com sucesso");

          // navigation to review
          this.navigation(
            `/checkout/summary/${this.transaction.transactionId}`
          );
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // get and commit current transaction
    async getCurrentTransaction() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
        });
      } catch (err) {
        console.log(err);
      }
    },

    // handle current transaction
    handleCurrent() {
      this.transaction = {
        id: this.current.data.id,
        transactionId: this.current.data.transactionId,
        code: this.current.data.code,
      };

      this.step = 2;
    },

    // copy pix code
    copyCode() {
      var textBox = document.getElementById("code");
      textBox.select();
      document.execCommand("copy");
    },

    // continue current transactions
    continueTransaction() {
      // continue transaction
      const id = this.jwtDecoded.awaitingTransactions.find(
        (e) => e.paymentMethod === "PICPAY"
      ).id;

      this.$root.$emit("continue-transaction", id);
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.centered-input {
  text-align: center;
  width: 100%;
  border-radius: 4px;
}
</style>
